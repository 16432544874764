import axios from 'axios';
import React, { Component } from 'react';
import { matrixRain } from './helperFunctions';
import mfkn from '../photos/mfkn.png';
import discordicon from '../photos/discordicon.png';
import instagramicon from '../photos/ig.png';
import tiktokicon from '../photos/tiktok.png';

class Screen extends Component {
    constructor(props){
        super(props);
        this.state = { 
            fullname: '',
            email: '',
            message: '',
            error: null
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidMount() {
        matrixRain();
    }

    handleFormSubmit(event, state) {
        event.preventDefault();
        axios({
            method: 'post',
            url: 'https://formspree.io/f/xlevqlqo',
            data: this.state
        })
        .then(result => {
            this.resetForm();
        })
        .catch(error => this.setState({ error: error.message }));
    }

    resetForm(){
        document.getElementById('thankYouMessage').style.display = "block";
        this.setState({fullname: '', email: '', message: ''});
    }

    closeThankYou() {
        document.getElementById('thankYouMessage').style.display = 'none';
    }

    render() {
        return (
            <div className="wrapper">
                <div id="screen">
                    <canvas id='matrixRain'></canvas>
                    <div id="mfknLogo">
                        <img className="image" src={mfkn} alt='sticker'></img>
                    </div>
                    <div id="twitch-embed">
                        <div className='title'><b>MFKN_AK Streaming on Kick</b></div>
                        <iframe
                            title="MFKN_AK Stream"
                            src="https://player.kick.com/mfkn_ak"
                            top="10%"
                            height="99.5%"
                            width="99.5%"
                            allowFullScreen={true}>
                        </iframe>
                    </div>
                    <div id="aboutTextContainer">
                        <div className='title'><b>About MFKN</b></div>
                        <div id="aboutText">
                            <pre>
        __    __     ______   __  __     __   __    	<br />
        /\ "-./  \   /\  ___\ /\ \/ /    /\ "-.\ \   	<br />
        \ \ \-./\ \  \ \  __\ \ \  _"-.  \ \ \-.  \  	<br />
        \ \_\ \ \_\  \ \_\    \ \_\ \_\  \ \_\\"\_\ 	<br />
        \/_/  \/_/   \/_/     \/_/\/_/   \/_/ \/_/ 	<br />
                                                        <br />
                                                        <br/>
        MFKN is an organization focused on bringing together <br/>
        like-minded gamers and creative minds. <br/>
        Our aim is to create an innovative and inclusive space <br/>
        for gamers and creatives alike to share ideas, <br/>
        collaborate on projects, and create something amazing. <br/>
        We strive to create an environment in which people of <br/>
        all backgrounds and levels of experience can come <br/>
        together and be inspired by one another. <br/>
        Join us and help us shape the future!<br/>
        Links:
                            </pre>
                        </div>
                        <a href="https://www.instagram.com/mfkn_ak/" target="_blank" rel="noreferrer">
                            <div id="instagramicondiv">
                                <img id="instagramicon" src={instagramicon} alt="icon" ></img>
                            </div>
                        </a>
                        <a href="https://www.tiktok.com/@mfkn_ak2" target="_blank" rel="noreferrer">
                            <div id="tiktokicondiv">
                                <img id="tiktokicon" src={tiktokicon} alt="icon"></img>
                            </div>
                        </a>
                        <a href="https://discord.gg/zR2fEWKSKP" target="_blank" rel="noreferrer">
                            <div id="discordicondiv">
                                <img id="discordicon" src={discordicon} alt="icon"></img>
                            </div>
                        </a>
                    </div>
                    <div id="storeContainer">
                        <div className='title'><b>MFKN Store</b></div>
                        <div id="shopifyContainer">
                            <div id='collection-component-1675208705560'></div>
                        </div>
                        <div id="storeText">
                            <pre>
                                &nbsp;&nbsp;@@@@@@@   @@@@@@   @@@@@@@@@@   @@@  @@@  @@@   @@@@@@@@      <br/>
                                &nbsp;@@@@@@@@  @@@@@@@@  @@@@@@@@@@@  @@@  @@@@ @@@  @@@@@@@@@      <br/>  
                                &nbsp;!@@       @@!  @@@  @@! @@! @@!  @@!  @@!@!@@@  !@@            <br/> 
                                &nbsp;!@!       !@!  @!@  !@! !@! !@!  !@!  !@!!@!@!  !@!            <br/> 
                                &nbsp;!@!       @!@  !@!  @!! !!@ @!@  !!@  @!@ !!@!  !@! @!@!@      <br/> 
                                &nbsp;!!!       !@!  !!!  !@!   ! !@!  !!!  !@!  !!!  !!! !!@!!      <br/> 
                                &nbsp;:!!       !!:  !!!  !!:     !!:  !!:  !!:  !!!  :!!   !!:      <br/> 
                                &nbsp;:!:       :!:  !:!  :!:     :!:  :!:  :!:  !:!  :!:   !::      <br/> 
                                &nbsp;&nbsp;::: :::  ::::: ::  :::     ::    ::   ::   ::   ::: :::: <br/> 
                                &nbsp;&nbsp;:: :: :   : :  :    :      :    :    ::    :    :: :: :  <br/> 
                                                                                               <br/>
                                                                                               <br/>             
                                &nbsp;&nbsp;@@@@@@    @@@@@@    @@@@@@   @@@  @@@  @@@     <br/>
                                &nbsp;@@@@@@@   @@@@@@@@  @@@@@@@@  @@@@ @@@  @@@          <br/>
                                &nbsp;!@@       @@!  @@@  @@!  @@@  @@!@!@@@  @@!          <br/>
                                &nbsp;!@!       !@!  @!@  !@!  @!@  !@!!@!@!  !@           <br/>
                                &nbsp;!!@@!!    @!@  !@!  @!@  !@!  @!@ !!@!  @!@          <br/>
                                &nbsp;&nbsp;!!@!!!   !@!  !!!  !@!  !!!  !@!  !!!  !!!     <br/>
                                &nbsp;     !!:  !!!  !!:  !!!  !!:  !!!  !!!  !!!          <br/>
                                &nbsp;!:!   :!: !:!  :!:  !:!  :!:  !:!  :!:               <br/>
                                &nbsp;:::: ::   ::::: ::  ::::: ::   ::   ::   ::          <br/>
                                &nbsp;&nbsp;:: : :     : :  :    : :  :   ::    :   :::    <br/>
                            </pre>
                        </div>
                    </div>
                    <div id="contactForm">
                        <div className='title'><b>Contact Form</b></div>
                        <form  onSubmit={e => {this.handleFormSubmit(e, this.state)}}>
                            <input type="text" id="fullname" name="fullname" placeholder="Your full name..." 
                            value={this.state.fullname} onChange={e => this.setState({fullname: e.target.value})}/>
                                
                            <input type="email" id="email" name="email" placeholder="Your email address..." 
                            value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>
                                
                            <textarea id="message" name="message" placeholder="Message goes here..." 
                            value={this.state.message} onChange={e => this.setState({message: e.target.value})}></textarea>
                                
                            <button type="submit" id="submit">Submit</button>
                        </form>
                    </div>
                    <div id="thankYouMessage">
                        <div id="thankYouMessageTitleArea">
                            <div className='closeButton' onClick={this.closeThankYou}>X</div>
                        </div>
                            <p>Thank You! <br />
                            Your message has been sent.</p>
                    </div> 
                </div>
            </div>
        );
    }
}

export default Screen